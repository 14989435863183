import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AOS from 'aos'
import 'aos/dist/aos.css'
import SliderDots from '../components/sliderDots';
import { Link as ScrollLink} from 'react-scroll';
import c1 from "../images/carousel_1.jpg"
import c2 from "../images/carousel_2.jpg"
import c3 from "../images/carousel_3.jpg"
import c4 from "../images/carousel_4.jpg"
import img1 from "../images/home_1.jpg"
import img2 from "../images/home_2.jpg"
import background from "../images/home_background.jpg"

export default function Home() {
  const[slide,setSlide]=useState(0);
  const [screenWidth, setScreenWidth] = useState(calculateInitialWidth);
  
  useEffect(() => {
    AOS.init({
      duration: 900, // You can adjust the duration as needed
      once: true, // Set to true if you want the animation to occur only once
    });
  }, []);

  const handleDotClick = (index) => {
    setSlide(index);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(calculateInitialWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calculateInitialWidth() {
    const isMobile = window.innerWidth <= 768; 
    return isMobile ? window.innerWidth - 40 : window.innerWidth - (40 * 8);
  }
  
  const slideContent=[
    {
      img: c3,
      h: "HVAC Solutions",
      desc: "Revolutionizing environments with meticulous precision, our HVAC solutions orchestrate a seamless symphony of comfort. Whether facing frosty winters or blistering summers, we engineer bespoke climate control solutions, ensuring optimal conditions year-round. Tailored to your unique requirements, our systems redefine the essence of personalized comfort."
    },
    {
      img: c1,
      h: "Cold Rooms",
      desc: "Elevating temperature control to new heights, our cold room solutions redefine refrigeration excellence. With precision engineering, we create environments that preserve the freshness of perishables. From frozen goods to delicate products, our cold rooms set the benchmark, ensuring optimal conditions for storage and extending the shelf life of diverse items."
    },
    {
      img: c2,
      h: "Clean Rooms",
      desc: "Crafting immaculate spaces, our cutting-edge clean room solutions establish a pinnacle of purity. Meticulously designed to eradicate contaminants, we redefine cleanliness for sectors where uncompromised quality takes precedence, ensuring optimal conditions for sensitive processes."
    },
    {
      img: c4,
      h: "Commercial Kitchen",
      desc: "Revolutionizing culinary operations with precision, our commercial kitchen equipment ensures seamless performance in every kitchen. Built to withstand the demands of busy environments, our cutting-edge designs enhance efficiency and productivity, empowering chefs to focus on creating exceptional dishes with ease."
    }
  ]
  const handleNextSlide=()=>{
    setSlide((prev)=>((prev+1)%4))
    console.log(screenWidth)
  }
  const handlePrevSlide=()=>{
    setSlide((prev)=>(((prev-1)+4)%4))
  }
  return (<>
  <div className=''>
    <img src={background} className='w-full object-cover h-screen absolute -z-10 inset-0'></img>
    <div className='w-full h-screen absolute -z-10 inset-0 bg-gradient-to-b from-[#eaeaea00] via-[#eaeaea2f] to-[#fafafa]'></div>
    <div className='flex h-full w-full items-center justify-center text-center' data-aos="fade-up">

      <div className='h-screen justify-center items-center m-5 mx-6 scale-105 flex flex-col'>

      <h1 className='font-anton border-b-4 md:text-5xl text-[40px] leading-[50px] text-[#1E1E1E] uppercase py-5 md:py-7 w-fit'> HVAC, Cold Rooms, Clean Rooms,<br style={{ lineHeight: '30px' }} />and Commercial Kitchen Equipment</h1>

        {/* <h2 className='font-anton text-2xl md:text-3xl text-[#1E1E1E] py-4 md:py-5'>HVAC, Cold Rooms, Clean Rooms, and Commercial Kitchen Equipment.</h2> */}
        <p className='font-poppins text-[#1E1E1E] text-[16px] font-light md:font-normal md:w-2/3'>Delivering cutting-edge HVAC systems, cold room solutions, clean room environments, and commercial kitchen equipment. Our mission is to provide unmatched comfort, precision, and efficiency for diverse industries and needs.</p>
        <ScrollLink to="about" smooth={true} duration={900}>
          <button className="bg-[#f2f3f4] hover:opacity-75 hover:scale-105 transition-all duration-500 md:w-52 w-40 text-[#1E1E1E]md:text-lg mt-10 font-poppins tracking-widest p-2">
            Explore More
          </button>
        </ScrollLink>      </div>
      {/* <div className='w-5/12 p-4 mt-28'>
        <img src={logo} className=' object-cover'></img>
      </div> */}
      {/* <div className='w-5/12 mt-20 p-16 items-center shadow-xl flex bg-[#0000005a] rounded-s-full overflow-hidden -z-10 backdrop-blur-md'>
        <img src={logo} className=' object-cover'></img>
      </div> */}
    </div>
    <div id="about" className=' md:pt-52 space-y-32 md:space-y-52'>
      <div  className='md:mx-40 justify-center md:space-x-12 md:flex'>
        <img src={img1} className='md:h-[500px] w-full md:p-0 p-5  md:shadow-2xl md:mx-10 md:w-1/2 object-cover'data-aos="fade-in"></img>
        <div className='justify-between text-center px-8 md:px-0 md:text-left flex flex-col md:pr-10' data-aos="fade-in">
          <h2 className='text-[#1E1E1E] uppercase font-poppins hidden md:block font-light bg-[#a7adb4] w-fit p-2 px-6 tracking-widest rounded-full'>#Solutions</h2>
          <h2 className='font-anton text-3xl md:text-[64px] text-[#1E1E1E] md:leading-[80px]   border-[#1E1E1E] border-b-4 py-3 mb-3 '>About <br className='md:block hidden'/>Delta-Q</h2>
          <p className='font-poppins font-light md:leading-5 text-justify  text-[16px] px-0 md:px-0 text-[#1E1E1E]'>Welcome to DeltaQ, your trusted partner for reliable solutions in HVAC, clean rooms, cold rooms, and commercial kitchen equipment across India. Known for our commitment to excellence and innovation, we specialize in delivering customized, high-performance systems tailored to meet diverse requirements. With a strong presence in South India, our expert team ensures meticulous design and engineering to achieve exceptional results. From advanced HVAC systems to sterile clean room environments, energy-efficient cold rooms, and cutting-edge commercial kitchen equipment, DeltaQ is dedicated to providing dependable solutions that set new standards for quality and reliability nationwide.</p>
          <Link to="/about-us" className='bg-[#a7adb4] hover:opacity-75 hover:scale-105 transition-all duration-500 md:w-52 text-[#1E1E1E] items-end text-lg mt-10 text-center font-poppins tracking-widest p-2'>More About us</Link >
        </div>
      </div>
      {/* make carousel out of these below statements */}
      <div className='mx-40 md:block hidden overflow-hidden ' data-aos="fade-up">
        <div className='flex items-center justify-between pr-6'>
      <h2 className='text-[#1E1E1E] mx-10 uppercase font-poppins font-light bg-[#a7adb4] w-fit p-2 px-6 mb-10 tracking-widest rounded-full'>#Services</h2>
      <SliderDots totalSlides={slideContent.length} currentSlide={slide} handleDotClick={handleDotClick} />

        </div>
        <div className={`flex transition-all duration-1000 ease-in-out w-full `} style={{transform: `translateX(-${slide * (screenWidth)}px)`, width: `${screenWidth * 4}px` }}>
{slideContent.map((s,i)=>(

<div key={i} className={`flex`} style={{ width: `${screenWidth}px` }}>
            <div className='justify-top space-y-8 flex flex-col pr-32 mx-10'>
         <h2 className='font-anton text-6xl text-[#1E1E1E] leading-[70px]'>{s.h}</h2>
          <p className='font-poppins font-light leading-6 text-[16px] text-justify text-[#1E1E1E]'>{s.desc}</p>
        </div>
            <img src={s.img} className='h-[325px] mx-10 w-1/2 object-cover' alt={`carousel-1`} />
        </div>
))
}
      </div>
      <div className='w-full md:flex justify-between px-10 pr-6 my-5'>
<div className='flex  space-x-4'>
        <button  className='bg-[#a7adb4] h-14 w-14 text-white p-4 ' onClick={handlePrevSlide}>
          <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.64">
              <path d="M18.6665 23.6666L18.6665 0.333292L0.33317 12L18.6665 23.6666Z" fill="white"/>
            </g>
          </svg>
        </button>
        <button  className='bg-[#a7adb4] h-14 w-14 text-white p-4 flex justify-center'  onClick={handleNextSlide}>
          <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g  opacity="0.64">
              <path d="M0.333496 0.333374V23.6667L18.6668 12L0.333496 0.333374Z" fill="white"/>
            </g>
          </svg>
        </button>
</div>
<Link to="/services"  className='bg-[#a7adb4] text-[#1E1E1E] h-14 tracking-widest px-[75px] font-poppins p-4 '  onClick={handleNextSlide}>
Learn more about Services we Provide
        </Link>
      </div>
    </div>
      <div className='md:hidden mx-5 overflow-hidden ' data-aos="fade-up">
      <div className={`flex transition-all duration-500 ease-in-out `} style={{transform: `translateX(-${slide * (screenWidth)}px)`, width: `${screenWidth * 3}px` }}>
{slideContent.map((s,i)=>(

<div key={i} className={`mx-5`} style={{ width: `${screenWidth}px` }}>
            <div className='justify-top space-y-14 flex flex-col '>
         <h2 className='font-anton text-4xl text-[#1E1E1E] text-center py-3 border-[#a7adb4] border-b-4 '>{s.h}</h2>
          <p className='font-poppins font-light text-[16px] text-justify text-[#1E1E1E]'>{s.desc}</p>
        </div>
            <img src={s.img} className='py-4 object-cover' alt={`carousel-1`} />
        </div>
))
}
      </div>
      <div className='w-full flex flex-col text-center justify-center my-5'>
<div className='flex  justify-center'>
<SliderDots totalSlides={slideContent.length} currentSlide={slide} handleDotClick={handleDotClick} />
        {/* <button  className='bg-[#3466AA] h-14 w-14 text-white p-4 ' onClick={handlePrevSlide}>
          <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.64">
              <path d="M18.6665 23.6666L18.6665 0.333292L0.33317 12L18.6665 23.6666Z" fill="white"/>
            </g>
          </svg>
        </button>
        <button  className='bg-[#3466AA] h-14 w-14 text-white p-4 flex justify-center'  onClick={handleNextSlide}>
          <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g  opacity="0.64">
              <path d="M0.333496 0.333374V23.6667L18.6668 12L0.333496 0.333374Z" fill="white"/>
            </g>
          </svg>
        </button> */}
</div>
<div className='flex justify-center  mx-5'>

<Link to="/services"  className='bg-[#a7adb4] hover:opacity-75 hover:scale-105 transition-all duration-500 w-full text-[#1E1E1E] text-lg mt-10 font-poppins tracking-widest p-2'  onClick={handleNextSlide}>
More Services
        </Link>
</div>
      </div>
    </div>
    <div className='md:px-40 pt-12  mb-60 bg-[#EFF1F3] text-center md:text-left justify-center md:space-x-12 md:flex'>
        <div className='justify-top space-y-14 flex flex-col md:pr-32 mt-10 md:mx-10' data-aos="fade-right">
          <h2 className='font-anton text-4xl md:text-7xl text-[#1E1E1E] md:leading-[80px] md:py-2'>Why DeltaQ?</h2>
          <p className='font-poppins leading-6 text-[16px] px-8 md:px-0  font-light  text-justify  text-[#1E1E1E]'>DeltaQ is a trusted leader in HVAC systems, clean rooms, cold rooms, and commercial kitchen equipment. We deliver energy-efficient, durable, and high-performance solutions for diverse industries. We combine innovation, reliability, and sustainability to set new benchmarks in comfort and functionality. Choose DeltaQ for excellence and a partnership you can depend on.

</p>
        </div>
        <img src={img2} className='md:h-[600px] mt-32 md:mt-0 md:mx-10 md:w-1/2 object-cover pb-10' data-aos="fade-in"></img>
      </div>
          </div>
  </div>
  </>
  )
}
