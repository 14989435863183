import React , {useEffect,useState} from 'react';
import img_main from '../images/image about_us.png';
import logo from '../images/logo3.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
export default function About_us() {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth-40);

  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth-40);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    AOS.init({
      duration: 1000, // You can adjust the duration as needed
      once: true, // Set to true if you want the animation to occur only once
    });
  }, []);
  
  return (
    <div className='md:p-0 '>
      <div className='md:mb-0 mb-32'>
        <img
        className='w-full md:h-full h-[600px] object-cover absolute -z-20  md:static'
        src={img_main}
        alt='Image 1'
        />
        <div className='w-full h-screen absolute inset-0 -z-10 bg-gradient-to-b from-[#ffffff00] via-[#ffffff2f] to-[#fafafa]'></div>
    
        <img
        className='md:absolute md:scale-150 pt-40 md:pt-0 w-full md:w-auto md:inset-60 mx-auto '
        src={logo}
        alt='Image 2'
        data-aos="fade-in"
        />
      </div>

      
<div data-aos="fade-up">

      <div className='text-center w-full flex md:mt-20 justify-center'>
        <h1 className='font-anton md:text-6xl text-3xl md:border-b-4 border-b-2 pb-3 md:pb-10 md:w-1/2 border-[#3466AA] '>HOW OUR JOURNEY STARTED</h1>
      </div>
      <div className='text-center text-[#3466AA]  flex justify-center pt-16 px-8 md:px-0 pb-10'>
        <p className='font-poppins font-light mb-24 md:mb-32 md:w-7/12 md:text-base text-sm text-justify'>DeltaQ emerged as a collaborative initiative, uniting the expertise of leading OEMs with a shared commitment to innovation and excellence in the energy solutions sector. Driven by a passion for quality and customer satisfaction, we have steadily expanded our footprint and now proudly maintain a strong presence in the dynamic state of Kerala.<br/><br/>

        We specialize in four key areas of expertise: advanced Heating, Ventilation, and Air Conditioning (<span className="font-semibold">HVAC</span>) systems, cutting-edge <span className="font-semibold">cold rooms</span>, high-performance <span className="font-semibold">clean rooms</span>, and reliable <span className="font-semibold">commercial kitchen equipment</span>. Our solutions are designed to deliver optimal comfort, precise temperature control, and superior efficiency across industries. At DeltaQ, we take pride in exceeding <span className="font-semibold">industry standards</span>, offering systems that guarantee exceptional performance, durability, and long-term reliability.<br/><br/>

        Clients are at the core of everything we do. Recognizing that each project is unique, our team works closely with clients to deliver tailored solutions that align with their specific needs. From the initial consultation to final implementation, we emphasize clear communication, transparency, and unwavering support to ensure every project is a success. DeltaQ remains dedicated to building strong relationships and providing innovative solutions that drive value for our clients.<br/><br/>

        </p>
      </div>
</div>

{/* <div data-aos="fade-up">
      <div className='text-center  w-full flex justify-center'>
        <h1 className='font-anton md:text-6xl text-3xl md:border-b-4 border-b-2 pb-3 md:pb-10 md:w-1/2 border-[#3466AA] '>OUR APPROACH</h1>
      </div>
      <div className='text-center text-[#3466AA]  flex justify-center pt-16 px-8 md:px-0 pb-10'>
        <p className='font-poppins font-light mb-24 md:mb-32 md:w-7/12 md:text-base text-sm text-justify'>Our unwavering commitment to a customer-centric approach defines the essence of DeltaQ's philosophy. At the core of our operations lies the understanding that each project is inherently unique, with distinct requirements and objectives. We pride ourselves on cultivating strong partnerships with our clients, acknowledging that their success is integral to our own.<br/><br/>

The journey begins with the initial consultation, a crucial phase where we delve into the intricacies of the project, gaining a profound understanding of our client's needs and expectations. This collaborative process sets the stage for the development of bespoke solutions, crafted with precision and tailored to the specific requirements of each project. We recognize that effective communication is paramount, and our team ensures a transparent and open dialogue at every step of the way.<br/><br/>

As the project unfolds, we remain dedicated to providing ongoing support, underscoring our commitment to the long-term success of our clients. This customer-centric ethos extends beyond the completion of a project; it becomes a lasting partnership where we continue to evolve our solutions in alignment with changing needs. At DeltaQ, our client relationships are not just transactions; they are enduring collaborations founded on trust, transparency, and a shared commitment to achieving excellence.<br/><br/>
        </p>
      </div>
</div> */}

      <div className='bg-[#Ebecee] text-center w-full flex flex-col items-center pt-16 md:pt-10' data-aos="fade-up">
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-10 md:w-1/2 border-[#3466AA] '>OUR OFFICE LOCATION</h1>
      <div className='md:hidden block '>
        {/* <div  className='flex justify-center mt-10 mb-4'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.8489620174923!2d76.99766757588569!3d11.049948554087221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858053fffffff%3A0xc4fd903a19f695ac!2sDelta%20Q%20Solutions!5e0!3m2!1sen!2sin!4v1705960219896!5m2!1sen!2sin" className=' border-4 border-[#3466AA88] ' width={screenWidth} height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>      
        </div> */}
          {/* <h2 className=' font-anton text-3xl md:text-5xl leading-[70px] text-[#114094]'>
        Coimbatore, Tamil Nadu
        </h2> */}
        {/* <p className='text-sm px-10 font-poppins w-3/4'>
        Mig 845, First Floor,Ganapathi Maanagar, Ganapahy, Coimbatore, Tamil Nadu 641006        </p>   */}
        <div  className='flex justify-center mt-20 mb-4'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.72294518368!2d76.91947507587014!3d8.52625639665415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05b900126512f9%3A0xd57dbd8b59e1139d!2sDelta%20Q%20Solutions!5e0!3m2!1sen!2sin!4v1705959270377!5m2!1sen!2sin" className=' border-4 border-[#3466AA88] ' width={screenWidth} height="300"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
          <h2 className=' font-anton text-3xl md:text-6xl leading-[70px] text-[#114094]'>
          Thiruvananthapuram, Kerala
        </h2>
        <p className='text-sm px-10 font-poppins mb-20  w-full'>
        TC-16/155, Vijaya Plaza, First Floor, Ulloor, Akkulam Road, Medical College (Po) Thiruvananthapuram - 695011.

       </p>  

        
      </div>
      <div className='md:block hidden'>
      {/* <div  className='flex justify-center space-x-10 my-20 mt-40 mx-40'> */}
      {/* <div className=' flex flex-col justify- text-left items-start'> 
        <h2 className=' font-anton text-6xl leading-[70px] text-[#114094]'>
        Coimbatore, Tamil Nadu
        </h2>
        <p className='text-lg font-poppins font-light mt-12  w-1/2'>
        Mig 845, First Floor,
Ganapathi Maanagar, Ganapahy, Coimbatore, Tamil Nadu 
641006        </p>
      </div>  */}
      {/* <div className='border-4 w-fit border-[#3466AA88]'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.8489620174923!2d76.99766757588569!3d11.049948554087221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858053fffffff%3A0xc4fd903a19f695ac!2sDelta%20Q%20Solutions!5e0!3m2!1sen!2sin!4v1705960219896!5m2!1sen!2sin" width="500" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>      </div> */}
      {/* </div> */}
      <div  className='flex justify-between space-x-10 my-20 mb-40 mx-40'>
      <div className='border-4 w-fit border-[#3466AA88]'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.72294518368!2d76.91947507587014!3d8.52625639665415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05b900126512f9%3A0xd57dbd8b59e1139d!2sDelta%20Q%20Solutions!5e0!3m2!1sen!2sin!4v1705959270377!5m2!1sen!2sin" width="500" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className=' flex flex-col justify-top text-left px-10 items-start'>
        <h2 className=' font-anton text-6xl leading-[70px] text-[#114094]'>
        Thiruvananthapuram, Kerala
        </h2>
        <p className='text-lg font-poppins font-light mt-12  w-2/3'>
        TC-16/155, Vijaya Plaza, First Floor, Ulloor, Akkulam Road, Medical College (Po) Thiruvananthapuram - 695011.
        </p>
      </div>
      </div>
      </div>
      </div>





    </div>
  )
}
