import React, {useState,useEffect} from 'react'
import { Link as ScrollLink, Events, animateScroll as scroll } from 'react-scroll';
import SliderDots from '../components/sliderDots';
import "../style/slider.css"
import "../style/main.css"
import img_main from '../images/image about_us.png';
import logo from '../images/logo2.png'
import s1 from '../images/services/services6.png'
import s2 from '../images/services/services2.png'
import s3 from '../images/services/services1.png'
import s4 from '../images/services/services3.png'
import s5 from '../images/services/services5.png'
import s6 from '../images/services/services4.png'
import s7 from '../images/services/services7.png'
import AOS from 'aos'
import 'aos/dist/aos.css'


export default function Services() {

  const[slide,setSlide]=useState(0);
  const [screenWidth, setScreenWidth] = useState(calculateInitialWidth);

  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(calculateInitialWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calculateInitialWidth() {
    // Check if it's a mobile device
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    // Return screenWidth - 40 for mobile, otherwise use the original calculation
    return isMobile ? window.innerWidth - 88 : 2*(window.innerWidth - (40 * 8))/3;
  }
  
  
  const slideContent=[
    {
      img: s1,
      h: "LG"
    },
    {
      img: s2,
      h: "Daikin",
      },
    {
      img: s3,
      h: "Middleby Celfrost",
       },
    {
      img: s4,
      h: "Bluestar",
      },
    {
      img: s5,
      h: "Trane",
      },
    {
      img: s6,
      h: "Thermoflux",
       },
    {
      img: s7,
      h: "Samsung",
      }
  ]
  const handleNextSlide=()=>{
    const isMobile = window.innerWidth <= 768;
    if (isMobile) setSlide((prev)=>((prev+1)%7))
    else setSlide((prev)=>((prev+1)%5))
  }
  const handlePrevSlide=()=>{
    const isMobile = window.innerWidth <= 768;
    if (isMobile) setSlide((prev)=>(((prev-1)+7)%7))
    else setSlide((prev)=>(((prev-1)+5)%5))
  }
  const handleDotClick = (index) => {
    setSlide(index);
  };

  useEffect(() => {
    AOS.init({
      duration: 900, // You can adjust the duration as needed
      once: true, // Set to true if you want the animation to occur only once
    });
  }, []);


  return (
    <div className='md:p-0'>
      <div className='text-center w-full flex justify-center h-screen p-4  flex-col items-center' data-aos="fade-up">
        <h1 className='font-anton text-3xl md:text-7xl border-b-4  pt-20 pb-4 md:pb-10 md:w-7/12 border-[#3466AA] uppercase '>Our Expertise, Your Advantage</h1>
        <div className='text-center text-[#3466AA]  flex justify-center pt-4 md:pt-10 pb-10'>
        <p className='font-poppins pb-4 md:w-9/12 md:font-light text-xs md:text-2xl text-justify-centre '>Delivering cutting-edge HVAC systems, efficient cold storage, state-of-the-art clean rooms, and robust commercial kitchens. Our expertise ensures superior performance, reliability, and energy efficiency for your business needs.
        </p>

      </div>
      <ScrollLink to="services" smooth={true} duration={900}>
          <button className="bg-[#3466AA] hover:opacity-75 hover:scale-105 transition-all duration-500 md:w-52 w-40 text-[#f2f3f4] md:text-lg mt-10 font-poppins tracking-widest p-2">
            Explore More
          </button>
        </ScrollLink>      </div>

      
<div id="services" className='bg-[#Ebecee] pt-24 py-14 pb-24 mb-24 md:mb-32' >

      <div data-aos="fade-up" >
      <div className='text-center w-full flex justify-center'>
        <h1 className='font-anton text-[#114084] md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>ADVANCED HVAC SOLUTIONS FOR EVERY SPACE</h1>
      </div>

      <div className='text-center text-black  flex justify-center pt-10 px-8 md:px-0 '>
        <p className='font-poppins font-light pb-4 md:w-7/12 md:text-base text-sm text-justify'>
        Our <span className="font-semibold">HVAC</span> systems are engineered to deliver exceptional climate control, ensuring superior <span className="font-semibold">air quality</span> and precise <span className="font-semibold">temperature regulation</span> for diverse commercial and industrial spaces. Whether it's a corporate office, retail store, healthcare facility, or industrial plant, our advanced solutions are tailored to meet the unique requirements of each environment. By integrating <span className="font-semibold">energy-efficient</span> designs with cutting-edge technology, we help businesses minimize <span className="font-semibold">energy consumption</span> and reduce operational costs without sacrificing comfort or performance. Each system is built to adhere to strict <span className="font-semibold">industry standards</span>, guaranteeing <span className="font-semibold">reliability</span>, <span className="font-semibold">durability</span>, and consistent long-term performance.
        <br/>
        <br/>
        
        By blending innovation with a commitment to <span className="font-semibold">sustainability</span>, we empower businesses to create healthier, more productive, and <span className="font-semibold">energy-efficient</span> environments. Our <span className="font-semibold">HVAC</span> systems not only meet the demands of today's challenging applications but also support a greener future by prioritizing efficiency and reducing environmental impact.</p>


      </div>
      </div>
</div>
<div data-aos="fade-up">
      <div className='text-center w-full flex justify-center pt-10' >
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>EFFICIENT AND RELIABLE COLD ROOM SOLUTIONS</h1>
        
      </div>

      <div className='text-center text-[#3466AA]  flex justify-center pt-10 mb-24 md:mb-32'>
        <p className='font-poppins pb-4 md:w-7/12 md:text-base text-sm px-8 md:px-0  font-light text-justify'>
        Our <span className="font-semibold">cold rooms</span> are meticulously designed to provide superior <span className="font-semibold">temperature control</span> and maintain consistent <span className="font-semibold">cold storage</span> conditions for a wide range of industries. From food and beverage storage to pharmaceutical and industrial applications, our solutions ensure optimal preservation of sensitive products. Built with advanced insulation and <span className="font-semibold">energy-efficient</span> cooling systems, our cold rooms help businesses minimize <span className="font-semibold">energy consumption</span> while delivering reliable performance even in demanding environments.
        <br/>
        <br/>
        Each <span className="font-semibold">cold room</span> is constructed using high-quality materials that meet strict <span className="font-semibold">industry standards</span> for <span className="font-semibold">durability</span>, <span className="font-semibold">hygiene</span>, and <span className="font-semibold">safety</span>. With customizable sizes and configurations, we cater to the unique requirements of different businesses, ensuring efficient use of space and resources. By combining innovation and a commitment to <span className="font-semibold">sustainability</span>, our cold room solutions empower businesses to maintain product quality while reducing operational costs and environmental impact.

        </p>
      </div>
      </div>
<div id="services" className='bg-[#Ebecee] pt-24 py-14 pb-24 mb-24 md:mb-32' >

<div data-aos="fade-up" >
<div className='text-center w-full flex justify-center'>
  <h1 className='font-anton text-[#114084] md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>ADVANCED CLEAN ROOMS FOR CRITICAL APPLICATIONS</h1>
</div>

<div className='text-center text-black  flex justify-center pt-10 px-8 md:px-0 '>
  <p className='font-poppins font-light pb-4 md:w-7/12 md:text-base text-sm text-justify'>
  Our <span className="font-semibold">clean rooms</span> are expertly designed to deliver controlled <span className="font-semibold">environments</span> with superior <span className="font-semibold">air filtration</span>, precise <span className="font-semibold">temperature</span>, and <span className="font-semibold">humidity regulation</span>. Ideal for industries such as pharmaceuticals, electronics, healthcare, and biotechnology, our clean rooms meet stringent <span className="font-semibold">industry standards</span> for cleanliness and contamination control. We offer a variety of <span className="font-semibold">clean room classes</span>, ranging from ISO 8 to ISO 5, tailored to meet the specific requirements of each customer.<br/>
  <br/>
  
  Built with durable, high-quality materials that emphasize <span className="font-semibold">hygiene</span> and compliance, our clean rooms are customizable in size, configuration, and performance capabilities. Modular designs ensure scalability and adaptability for future needs, while energy-efficient systems help reduce operational costs. By blending innovation with a commitment to <span className="font-semibold">sustainability</span>, our clean room solutions provide businesses with the precision and flexibility they need for critical applications.</p>


</div>
</div>
</div>
<div data-aos="fade-up">
      <div className='text-center w-full flex justify-center pt-10' >
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>RELIABLE AND EFFICIENT COMMERCIAL KITCHEN EQUIPMENT</h1>
        
      </div>

      <div className='text-center text-[#3466AA]  flex justify-center pt-10 mb-24 md:mb-32'>
        <p className='font-poppins pb-4 md:w-7/12 md:text-base text-sm px-8 md:px-0  font-light text-justify'>
        Our <span className="font-semibold">commercial kitchen equipment</span> is designed to meet the demanding needs of foodservice businesses, offering superior <span className="font-semibold">performance</span>, <span className="font-semibold">durability</span>, and <span className="font-semibold">efficiency</span>. From cooking ranges and refrigeration units to dishwashers and food prep stations, our products cater to a wide range of establishments, including restaurants, hotels, cafeterias, and catering services. As proud partners of <span className="font-semibold">Middleby</span>, a global leader in commercial kitchen solutions, we bring cutting-edge innovations and world-class quality to every project.

<br/>
        <br/>
        With a focus on <span className="font-semibold">energy efficiency</span> and robust construction, our <span className="font-semibold">kitchen equipment</span> is built to reduce operational costs while ensuring long-term reliability. We offer customizable options to suit the unique layouts and requirements of different kitchens, maximizing space and productivity. By combining advanced technology with a commitment to <span className="font-semibold">sustainability</span>, our commercial kitchen solutions empower businesses to deliver exceptional service while maintaining high standards of hygiene and efficiency.
        </p>
      </div>
      </div>
<div className='mx-40 my-40 border-t-4 border-b-4 border-[#114084] py-14 pb-20 md:block hidden'>
<div className='flex w-full'>
  <div className='w-1/3 flex flex-col justify-top py-6 pr-14' data-aos="fade-right">
<h2 className='font-anton text-6xl leading-[70px] text-[#114084]'>
  Brands we work with
  </h2>
{/* <p className='font-poppins text-base font-light pt-10 text-[#1E1E1E]'>
DeltaQ excels in crafting bespoke cooling solutions, catering to a range of needs, from industrial installations to transport and specialized applications.
</p> */}
</div>
<div className=' flex items-center ' data-aos="fade-in">
<button  className='' onClick={handlePrevSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>

<div className='w-2/3  overflow-hidden' data-aos="fade-in">

        <div className={`flex transition-all duration-500`} 
  style={{
    transform: `translateX(-${slide * (screenWidth/3)}px)`,
    width: `${screenWidth*7/3}px`,
  }}>
{slideContent.map((s,i)=>(

<div key={i} className={`flex flex-col items-center  p-5 px-12  text-center justify-top `} style={{ width: `${screenWidth/3}px` }}>
            <img src={s.img} className='h-20 w-20 object-cover mb-6' alt={`carousel-1`} />
            <div className='justify-top flex flex-col '>
         <h2 className='font-poppins uppercase font-bold text-lg mb-4 text-[#114084]'>{s.h}</h2>
          <p className='font-poppins font-light text-[13px] text-[#1E1E1E]'>{s.desc}</p>
        </div>
        </div>
))
}
      </div>
      <SliderDots totalSlides={slideContent.length-2} currentSlide={slide} handleDotClick={handleDotClick} />
</div>
      <div className=' flex items-center' data-aos="fade-in">
<button  className='rotate-180' onClick={handleNextSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>


  </div>
    </div>
<div className='mx-5 text-center my-40 border-t-4 border-b-4 border-[#114084] py-10 pb-20 md:hidden block'>
<div className='w-full ' >
  <div className='flex flex-col  py-2'>
<h2 className='font-anton text-4xl pb-10 text-[#114084]'>
  Brands we work with
  </h2>
</div>
<div className='flex'>
<div className=' flex items-center'>
<button  className='' onClick={handlePrevSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>

<div className='  overflow-hidden'>

        <div className={`flex transition-all duration-500`} 
  style={{
    transform: `translateX(-${slide * (screenWidth)}px)`,
    width: `${screenWidth*7}px`,
  }}>
{slideContent.map((s,i)=>(

<div key={i} className={`flex flex-col items-center  p-5  mt-10 justify-top `} style={{ width: `${screenWidth}px` }}>
            <img src={s.img} className='h-14 w-14 object-cover mb-6' alt={`carousel-1`} />
            <div className='justify-top flex flex-col '>
         <h2 className='font-poppins uppercase font-bold text-xl mb-4 text-[#114084]'>{s.h}</h2>
          <p className='font-poppins font-light text-sm text-[#1E1E1E]'>{s.desc}</p>
        </div>
        </div>
))
}


      </div>
      <SliderDots totalSlides={slideContent.length} currentSlide={slide} handleDotClick={handleDotClick} />

</div>
      <div className=' flex items-center'>
<button  className='rotate-180' onClick={handleNextSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>
</div>

  </div>
    </div>


{/* <div data-aos="fade-up">

      <div className=' text-center w-full flex justify-center pt-10'>
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>CUSTOMER-CENTRIC APPROACH</h1>
        
      </div>

      <div className='text-center text-[#3466AA]  flex justify-center px-8 md:px-0 pt-10 pb-10'>
        <p className='font-poppins font-light pb-4 md:w-7/12 md:text-base text-sm  mb-24 md:mb-32 text-justify'>Our unwavering commitment to a <span className="font-semibold">customer-centric approach</span> defines the essence of <span className="-semibold">DeltaQ's</span> philosophy. At the core of our operations lies the understanding that each project is inherently unique, with distinct requirements and objectives. We pride ourselves on cultivating strong partnerships with our clients, acknowledging that their success is integral to our own.
        <br/>
        <br/>
The journey begins with the initial consultation, a crucial phase where we delve into the intricacies of the project, gaining a profound understanding of our client's needs and expectations. This collaborative process sets the stage for the development of <span className="font-semibold">bespoke solutions</span>, crafted with precision and tailored to the specific requirements of each project. We recognize that effective communication is paramount, and our team ensures a transparent and open dialogue at every step of the way.
<br/>
        <br/>
As the project unfolds, we remain dedicated to providing ongoing support, underscoring our commitment to the long-term success of our clients. This <span className="font-semibold">customer-centric ethos</span> extends beyond the completion of a project; it becomes a lasting partnership where we continue to evolve our solutions in alignment with changing needs. At <span >DeltaQ</span>, our client relationships are not just transactions; they are enduring collaborations founded on trust, transparency, and a shared commitment to achieving excellence.</p>

      </div>
</div> */}





    </div>
  )
}